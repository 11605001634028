<template>
  <div v-if="!onlyFrom" class="fromTo-block">
    <div class="fromTo-block-container">
      <div class="fromTo-block-item from" :class="showFrom ? '-open' : ''" @click="(e) => { open(e, 'from') }">
        <h4>{{ $t('from') }}</h4>
        <VueMonthlyPicker ref="from" type="from" v-model="selectedMonth" :placeHolder="from" v-on:selected="setValFrom"
          :max="momentTo"> </VueMonthlyPicker>
      </div>
    </div>
    <div class="fromTo-block-container">
      <div class="fromTo-block-item to" :class="showTo ? '-open' : ''" @click="(e) => { open(e, 'to') }">
        <h4>{{ $t('to') }}</h4>
        <VueMonthlyPicker ref="to" type="to" v-model="selectedMonth" :placeHolder="to" v-on:selected="setValTo"
          :min="minTo" :max="momentSelectMonth"> </VueMonthlyPicker>
      </div>
    </div>
  </div>
  <div v-else class="fromTo-block">
    <div class="fromTo-block-container">
      <div class="fromTo-block-item to" :class="showTo ? '-open' : ''" @click="(e) => { open(e, 'to') }">
        <h4>{{ $t('current-month') }}</h4>
        <VueMonthlyPicker ref="to" type="to" v-model="selectedMonth" :placeHolder="to" v-on:selected="setValTo"
          :max="momentSelectMonth">
        </VueMonthlyPicker>
      </div>
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
