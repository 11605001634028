import VueMonthlyPicker from '../VueMonthlyPicker/VueMonthlyPicker'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'fromTo',
  props: {
    infos: Array,
    onlyFrom: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  components: {
    VueMonthlyPicker
  },
  data: () => {
    return {
      selectedMonth: moment().format('MMM YYYY'),
      // placeholderFrom: moment().format('MMM YYYY'),
      // placeholderTo: moment().format('MMM YYYY')
      showFrom: false,
      showTo: false
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions('datepicker', {
      setFromTo: 'setFromTo'
    }),
    setValFrom (e) {
      this.dateFrom = e
      // this.placeholderFrom = e.format('MMM YYYY')
      this.setFromTo({
        from: e.format('MMM YYYY')
      })
    },
    setValTo (e) {
      this.dateto = e
      // this.placeholderTo = e.format('MMM YYYY')
      this.setFromTo({
        to: e.format('MMM YYYY')
      })
    },
    open (e, el) {
      if (el === 'from') {
        if (!this.$refs.from.$refs.popover.contains(e.target)) {
          this.$refs.from.showMenu = !this.$refs.from.showMenu
          this.showFrom = this.$refs.from.showMenu
        }
      } else {
        if (!this.$refs.to.$refs.popover.contains(e.target)) {
          this.$refs.to.showMenu = !this.$refs.to.showMenu
          this.showTo = this.$refs.to.showMenu
        }
      }
    }
  },
  computed: {
    ...mapGetters('datepicker', {
      from: 'from',
      to: 'to'
    }),
    momentFrom () {
      return moment(this.from, 'MMM YYYY')
    },
    momentTo () {
      return moment(this.to, 'MMM YYYY')
    },
    momentSelectMonth () {
      return moment(this.selectedMonth, 'MMM YYYY')
    },
    minTo () {
      return moment(this.from, 'MMM YYYY')
    }
  }
}
